<template>
  <v-navigation-drawer
      style="z-index: 21"
      app
      fixed
      dark
      v-model="showNav.showNav"
  >

    <v-list nav dense>
      <v-list-item dark class="py-1">
        <v-list-item-avatar size="36">
          <v-img src="http://qn.1947.cloud/small/arctic-circle-logo.svg"/>
        </v-list-item-avatar>
        <v-list-item-title>Small Nib</v-list-item-title>
      </v-list-item>

      <v-list-item dark two-line v-if="isLogin">
        <v-list-item-avatar size="60">
          <v-img :src="user.avatar"/>
        </v-list-item-avatar>
        <!-- 用户栏 -->
        <v-list-item-content>
          <v-list-item-title class=" mb-1">{{ user.nickName }}</v-list-item-title>
          <p style="color: #C8E6C9;font-size: 12px">Your tier: {{ 'Premium' }}</p>
          <v-list-item-subtitle class="mt-n1 py-1">
            <v-btn class="mr-2 " icon rounded x-small @click="$router.push('User')" color="blue">
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>

            <v-btn class="mr-2 " icon rounded x-small color="">
              <v-icon>
                mdi-email
              </v-icon>
            </v-btn>

            <v-btn class="mr-2 " icon rounded x-small @click="openLogout" color="teal">
              <v-icon>
                mdi-logout-variant
              </v-icon>
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider style="background-color: #C8E6C9"></v-divider>
      <v-subheader>common</v-subheader>

      <v-list-item
          link
          v-for="[icon, text,color] in links" :key="icon"
          @click="$router.push(text)"
      >
        <v-list-item-icon>
          <v-icon :color="color">{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text--13">{{  $vuetify.lang.t("$vuetify.menu." + text)  }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-subheader class="mt-8" v-if="nav !== null">System</v-subheader>
      <v-list-group
          color="blue"
          v-if="nav !== null"
          v-for="item in nav"
          :key="item.label"
          :prepend-icon="item.icon"
      >

        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.label"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item class="px-4" v-for="child in item.children" :key="child.label" @click="clickMenu(child)">
          <v-list-item-icon>
            <v-icon>mdi-circle-medium</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="child.label"></v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list-group>
    </v-list>

    <DialogLogout/>
  </v-navigation-drawer>
</template>

<script>
import Avatar from "@/components/Avatar";
import Login from "@/components/Login";
import DialogLogout from "./DialogLogout";
import {mapState} from 'vuex'
import vuetify from "@/plugins/vuetify";

export default {
  name: "Nav",
  components: {
    Avatar,
    Login,
    DialogLogout
  },

  data() {
    return {
      dialogLogout: false,
      isLoinTemp: false,
      valid: false,
      loading: false,
      loginForm: {
        userNumber: '',
        password: '',
        loginType: 0,
        code: ''
      },

      phoneRules: [
        v => !!v || 'phone is required',
        v => v.length <= 15 || 'phone must be less than 10 characters',
      ],
      passwordRules: [
        v => !!v || 'password is required',
        v => v.length <= 15 || 'password must be less than 10 characters',
      ],

      links: [
        ['mdi-domain', 'Home', 'green darken-2'],
        ['mdi-bookshelf', 'Note', 'blue'],
        /*     ['mdi-delete', 'Trash'],
             ['mdi-alert-octagon', 'Spam'],*/
      ],
    }
  },

  methods: {
    //菜单点击事件
    clickMenu(item) {
      //排除相同菜单重复点击 与 首页重复点击
      if (item.path !== this.$route.path) {
        this.$router.push(item.path)
      }
    },
    //打开退出登录确认框
    openLogout() {
      this.$store.commit('OPEN')
    }
  },
  computed: {
    ...mapState({
      showNav: state => state.drawer,
      user: state => state.token.getUserInfo,
      isLogin: state => state.token.isLogin,
      nav: state => state.nav.menuDate,
    })
  },

}
</script>

<style scoped>

</style>