export default {

    state: {
        isLogin: localStorage.getItem('register'),
        getUserToken: localStorage.getItem('token'),
        getUserInfo: JSON.parse(localStorage.getItem('user')),

        set IsLogin(value){
            localStorage.setItem('register',value)
        },

        set UserToken(value) {
            localStorage.setItem('token', value)
        },

        set UserInfo(value){
            localStorage.setItem('user',JSON.stringify(value))
        }
    },

    mutations: {
        LOGIN_IN(state, data) {
            state.IsLogin = true
            state.UserToken = data.accessToken
            state.UserInfo = data
        },
        LOGIN_OUT(state) {
            state.isLogin = false
            localStorage.clear()
        }
    }
}