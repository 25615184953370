export default {

    /**
     * @param msg 信息
     * @param color snackbar 颜色
     * @param visible 是否可见
     * @param showClose 关闭按钮
     * @param timeout 停留持续时间
     */
    state: {
        msg: '',
        color: '',
        icon: '',
        visible: false,
        showClose: true,
        timeout: 2000,
    },

    // 逻辑处理,同步函数
    mutations: {
        OPEN_SNACKBAR(state, options) {
            state.visible = true
            state.msg = options.msg
            switch (options.type){
                case 'err':
                    state.color = 'red'
                    state.icon = 'mdi-alert-circle'
                    break;
                case 'warn':
                    state.color = 'orange'
                    state.icon = 'mdi-alert-outline'
                    break
                default:
                    state.color = 'green'
                    state.icon= 'mdi-check-circle-outline'
            }
        },
        CLOSE_SNACKBAR(state) {
            state.visible = false

        },
        setShowClose(state, isShow) {
            state.showClose = isShow
        },
        setTimeout(state, timeout) {
            state.timeout = timeout
        },
    },
    // 逻辑处理,异步函数
    actions :{
        openSnackbar (context,options){
            let timeout = context.state.timeout
            context.commit('OPEN_SNACKBAR',{
                msg:options.msg,
                type:options.type
            })
            setTimeout(()=>{
                context.commit('CLOSE_SNACKBAR')
            },timeout)
        }
    }

}