import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import '@mdi/font/css/materialdesignicons.css'

import router from './router'



//引入mavonEditor 富文本
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)

Vue.config.productionTip = false;

new Vue({
    store,
    vuetify,
    router,
    render: (h) => h(App),
    created(){
        store.commit("SET_ROUTE",router)
    }
}).$mount("#app");
