import axios from "../../utils/http";
import store from "../../store";

//添加笔记列表
export async function saveNote(params) {
    const callBackData = await axios.post("portal/note/saveNote", params);
    store.dispatch('openSnackbar',{msg:'笔记添加成功'})
    if (!callBackData) return;
    return callBackData;
}

//获取笔记列表
export function getNoteList(params) {
    const callBackData = axios.post("portal/note/getNoteList", params);
    if (!callBackData) return;
    return callBackData;
}

//更新笔记内容
export async function updateNoteContent(params) {
    const callBackData = await axios.post("portal/note/update", params);
    store.dispatch('openSnackbar',{msg:'笔记更新成功'})
    if (!callBackData) return;
    return callBackData;
}

//删除笔记内容
export async function deletedNote(params) {
    const callBackData = await axios.post("portal/note/deleted", params);
    store.dispatch('openSnackbar',{msg:'笔记删除成功'})
    if (!callBackData) return;
    return callBackData;
}




