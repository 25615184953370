<template>


  <v-row class="justify-center">
    <v-col class="text-center col-md-10 col-12" s>
      <v-avatar color="primary" size="86">
        <img :src="userInfo.avatar">
      </v-avatar>
      <h3 class="mb-4">
        Welcome, {{ userInfo.nickName }}
      </h3>
      <p>
        Manage your info, privacy, and security to make UI Lib work better for you. <a style="color: blueviolet"
                                                                                       ref="#">Learn more</a>
      </p>
    </v-col>

    <v-col class="col-md-5 col-12">
      <v-card>
        <div class="d-flex justify-space-between pa-3">
          <div class="pr-3">
            <h3 class="text-18">Privacy & personalization</h3>
            <p>See the data in your UI Lib Account and choose what activity is saved to personalize your
              UI Lib experience</p>
          </div>
          <v-img style="height: 96px;width: 96px"
                 src="http://qn.1947.cloud/small/artist.svg">
          </v-img>
        </div>
        <v-divider></v-divider>
        <div class="v-card__actions blue--text grey lighten-5 py-4 " style="cursor: pointer">Manage your data &
          personalization
        </div>
      </v-card>

    </v-col>

    <v-col class="col-md-5 col-12">
      <v-card>
        <div class="d-flex justify-space-between pa-3">
          <div >
            <h3 class="text-18">Critical security issues found</h3>
            <p>Protect your account now by resolving the issues</p>
          </div>

          <div style="height: 96px;width: 96px">
            <v-img
                src="http://qn.1947.cloud/small/data-security.svg">
            </v-img>
          </div>

        </div>

        <v-divider></v-divider>
        <div class="v-card__actions blue--text grey lighten-5 py-4 " style="cursor: pointer">Take action</div>
      </v-card>
    </v-col>

    <v-col class="col-md-5 col-12">
      <v-card>
        <div class="d-flex justify-space-between pa-3">
          <div >
            <h3 class="text-18">Account storage</h3>
            <p>Your account storage is shared across UI Lib services, like UI Lib and Photos</p>
          </div>

          <div style="height: 96px;width: 96px">
            <v-img
                src="http://qn.1947.cloud/small/folder.svg">
            </v-img>
          </div>

        </div>

        <v-divider></v-divider>
        <div class="v-card__actions blue--text grey lighten-5 py-4 " style="cursor: pointer">Manage Storage</div>
      </v-card>
    </v-col>

    <v-col class="col-md-5 col-12">
      <v-card>
        <div class="d-flex justify-space-between pa-3">
          <div class="text-18">
            <h3>Critical security issues found</h3>
            <p>Protect your account now by resolving the issues</p>
          </div>

          <div style="height: 96px;width: 96px">
            <v-img
                   src="http://qn.1947.cloud/small/data-security.svg">
            </v-img>
          </div>
        </div>

        <v-divider></v-divider>
        <div class="v-card__actions blue--text grey lighten-5 py-4 " style="cursor: pointer">Get started</div>
      </v-card>
    </v-col>

    <v-col class="col col-10">
      <v-row>
        <v-col class="col-md-10 col-10">
          <p>Only you can see your settings. You might also want to review your settings for Maps,
            Search, or whichever UI Lib services you use most. UI Lib keeps your data private,
            safe, and secure. <a ref="#" style="color: blueviolet">Learn more</a></p>
        </v-col>

        <v-col class="col-md-2 col-2">
          <v-avatar color="primary" size="48">
            <img src="http://qn.1947.cloud/small/secret.svg">
          </v-avatar>
        </v-col>
      </v-row>
    </v-col>
  </v-row>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "User",
  data() {
    return {}
  },
  computed: {
    ...mapState({
      userInfo: state => state.token.getUserInfo,
      avatar: state => state.token.getUserInfo.avatar,
      email: state => state.token.getUserInfo.email,
      name: state => state.token.getUserInfo.nickName,
    })
  },
}
</script>

<style scoped>

</style>