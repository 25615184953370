<template>
    <!-- Stack the columns on mobile by making one full-width and the other half-width -->
    <v-row>
        <v-col sm="3">
            <v-card>
                <v-toolbar elevation="1" dark
                           style="background-image: url(https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg)">
                    <!-- 搜索框 -->
                    <v-text-field
                            class="mt-5"
                            prepend-inner-icon="mdi-select-search"
                            color="blue"
                            v-model="search"
                            label="Search Note"
                            flat
                            outlined
                            hide-details
                    ></v-text-field>
                    <!-- 新建笔记 -->
                    <v-dialog
                            v-model="newNoteDialog"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                    >
                        <v-card>
                            <v-toolbar
                                    dark
                                    color="light-blue darken-4"
                            >
                                <v-btn
                                        icon
                                        dark
                                        @click="newNoteDialog = false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>NEW NOTE</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                            dark
                                            text
                                            @click="addNote"
                                    >
                                        <h3>Save</h3>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>


                            <v-form
                                    class="pa-2"
                                    ref="form"
                                    lazy-validation
                            >
                                <v-card-title style="background-color:#C8E6C9">You can choose bookshelves if you
                                    already have
                                    them, or you
                                    can create new ones
                                </v-card-title>

                                <v-col class="mx-auto">
                                    <v-row>
                                        <v-col cols="12" md="2">
                                            <v-text-field
                                                    outlined
                                                    clearable
                                                    :rules="!!newNote.id ? rules  : saveNoteRules"
                                                    :disabled="!!newNote.id"
                                                    prepend-inner-icon="mdi-book-open-page-variant"
                                                    v-model="newNote.noteName"
                                                    label="NEW BOOKRACK"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="3">
                                            <v-text-field
                                                    clearable
                                                    outlined
                                                    :disabled="!!newNote.id"
                                                    prepend-inner-icon="mdi-unicode"
                                                    v-model="newNote.icon"
                                                    label=" ICON-NAME"
                                                    prefix="mdi-"

                                            >
                                                <template v-slot:append-outer>

                                                    <v-btn @click="selectIcon">
                                                        Select ICON
                                                    </v-btn>
                                                </template>
                                            </v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="3">
                                            <v-select
                                                    clearable
                                                    :rules="!!newNote.noteName ? rules  : saveNoteRules"
                                                    :disabled="!!newNote.noteName"
                                                    prepend-inner-icon="mdi-bookshelf"
                                                    v-model="newNote.id"
                                                    :items="notes"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="SELECT BOOKRACK"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-text-field
                                            :rules="saveNoteContentRules"
                                            prepend-inner-icon="mdi-book-open-page-variant"
                                            v-model="newNote.noteContentName"
                                            label="NOTE NAME"
                                            required
                                    ></v-text-field>
                                </v-col>
                            </v-form>

                            <v-divider></v-divider>

                            <v-col width="100%">
                                <mavon-editor v-model="newNote.text" style="z-index:14;height:calc(69vh - 110px);"
                                              :ishljs="true"
                                              @save="updateNote(selected)"/>
                            </v-col>

                        </v-card>
                    </v-dialog>
                    <template v-slot:extension>
                        <v-btn
                                fab
                                color="cyan"
                                bottom
                                absolute
                                @click="openNewNote"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                </v-toolbar>

                <v-list dense shaped height="calc(93vh - 160px)" class="overflow-auto" dark
                        style="background-size:cover;background-image: url(https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg)">
                    <v-subheader inset>
                    </v-subheader>
                    <v-list-group
                            v-for="item in notes"
                            :key="item.name"
                            :prepend-icon="item.icon"
                            sub-group
                            no-action
                            color="cyan"
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                                v-for="child in item.children"
                                :key="child.name"
                                color="blue"
                                @click="byValue(child)"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="child.name"></v-list-item-title>
                            </v-list-item-content>

                            <v-btn
                                    icon
                                    @click="openDialogDeleteNote(child.id,item.id)"
                            >
                                <v-icon v-if="child.type !== 'system'">mdi-file-document-remove-outline</v-icon>
                            </v-btn>
                        </v-list-item>
                    </v-list-group>
                </v-list>

            </v-card>
        </v-col>

        <v-col sm="9">
            <v-card outlined tile>
                <div
                        v-if="!selected"
                        class="text-h5 grey--text text--lighten-1 font-weight-light display justify-center "
                        style="display: flex;"
                >
                    You don't have notes yet, please create them first<h3 style="color: cadetblue">[NEW NOTE]</h3>
                </div>

                <mavon-editor
                        toolbarsBackground="#333"
                        editorBackground="#80CBC"
                        v-if="selected"
                        style="z-index:14;height: calc(93vh - 50px);color: #333;font-weight: 600;"
                        :ishljs="true"
                        v-model="selected.text"
                        codeStyle="srcery"
                        @save="updateNote(selected)"/>
            </v-card>

        </v-col>

        <v-dialog v-model="dialogDeleteNote" max-width="700px" dark>
            <v-card>
                <v-card-title class="text-h5" style="color: #e05769">您确定要删除笔记?</v-card-title>
                <v-card-title class="text-h5" style="color: #9e0c1e">删除笔记后,父目录下没有笔记默认删除父目录,请知悉!!!</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="cancelDialogDeleteNote">Cancel</v-btn>
                    <v-btn @click="deleteNote">delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>


</template>

<script>
    import {getNoteList, saveNote, updateNoteContent, deletedNote} from '../../api/note/note'

    export default {
        name: "Note",
        data: () => ({
          alert: true,
            dialogDeleteNote: false,
            newNoteDialog: false,
            search: null,
            active: [],
            open: [],
            notes: [],
            selected: null,
          projectIntroduction:[{
            id: 1,
            name: "整体项目介绍",
            icon: 'mdi-folder',
            children: [
              {
                id: 1,
                name: '前端内容介绍',
                text: '前端内容介绍123123123123',
                type: 'system',
              },
              {
                id: 2,
                name: '后端内容介绍',
                text: '后端内容介绍123123',
                type: 'system',
              }
            ]
          }],
            newNote: {
                id: '',
                userId: '',
                noteName: '',
                icon: '',
                noteContentName: '',
                text: '',
            },
            saveNoteContentRules: [
                (v) => !!v || '请输入笔记名称',
            ],
            saveNoteRules: [
                v => !!v || '请输入新书架或选择已有书架',
            ],
            rules: [
                true
            ],
            deletedNote: {
                parentId: '',
                sonId: '',
            },
        }),
        methods: {
            selectIcon() {
                window.open('https://pictogrammers.com/library/mdi/', "_blank")
            },
          openNewNote(){
            if (!this.$store.state.token.isLogin) {
              this.$store.dispatch('openSnackbar',{msg:'Log in first to store your notes',type: 'warn'})
              this.$store.commit('OPEN_LOGIN');
              return
            }
            this.newNoteDialog = !this.newNoteDialog
          },
            openDialogDeleteNote(sonId, pId) {
                this.deletedNote.sonId = sonId
                this.deletedNote.parentId = pId
                this.dialogDeleteNote = true
            },
            cancelDialogDeleteNote() {
                this.deletedNote = {}
                this.dialogDeleteNote = false
            },
            //赋值编辑框内容
            byValue(child) {
                this.selected = child
            },
            //添加笔记
            async addNote() {
                if (this.$refs.form.validate()) {
                    if (this.newNote.icon !== '' || this.newNote.icon !== null) {
                        this.newNote.icon = 'mdi-' + this.newNote.icon
                    }
                    this.newNote.userId = this.$store.state.token.getUserInfo.id
                    await saveNote(this.newNote)
                    this.newNote = {}
                    this.newNoteDialog = false
                    //添加成功重新获取笔记
                    await this.getNote()
                }
            },
            //更新笔记
            updateNote(item) {
                const params = {
                    id: item.id,
                    text: item.text,
                }
                updateNoteContent(params)
            },
            //删除笔记
            async deleteNote() {
                const params = {
                    parentId: this.deletedNote.parentId,
                    sonId: this.deletedNote.sonId,
                }
                await deletedNote(params)
                await this.getNote()
                this.deletedNote = {}
                this.dialogDeleteNote = false
            },
            //获取笔记
            async getNote() {
                if (!this.$store.state.token.isLogin) {
                  this.notes = this.projectIntroduction
                  this.selected = this.projectIntroduction[0].children[0]
                  return
                }
                const params = {
                    userId: this.$store.state.token.getUserInfo.id
                }
                const data = await getNoteList(params)
                if (null === data) {
                    this.notes = null
                } else {
                    this.notes = data.noteList
                }
            },
        },

        created() {
            this.getNote()
        },
    }
</script>

<style scoped>

</style>

