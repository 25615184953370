import zhHans from 'vuetify/es5/locale/zh-Hans';  // 导入Vuetify 自带的简体中文语言包

export default {
    label: '简体中文',
    // page login
    login: '登  录',
    other_login: '其他登录',
    sms_code: ' 手机短信登录',
    password: '密码登录',
    register: '点我注册.',
    cancel: '关闭',


    username: '用户名',

    login_account: '登录帐户',
    // menu
    menu: {
        Home: '首页',
        Note: '笔记',
    },

    //主頁
    home: {
        feature: '功能',
        introduce_1: '该应用是一款在线多端可及时笔记阅览及在线编辑应用',
        introduce_2: '本应用程序由(Nick  Zhong)免费提供，并且是永久免费的。欢迎推广。开源代码,',
        learn: '了解更多.'
    },
    // 展开 简体中文语言包的所有项目
    ...zhHans,
}
