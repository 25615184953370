

export default {
    state: {
        //登录
        dialogLogin: false,
        dialogLogout: false,
        userDrawer: false,
        newsDrawer: false,
        showNav: true,
    },

    mutations: {

        //登录弹出框
        CANCEL_LOGIN(state){
            state.dialogLogin = false;
        },
        OPEN_LOGIN(state){
            state.dialogLogin = true;
        },

        //登出弹出框
        CANCEL(state){
            state.dialogLogout = false;
        },
        OPEN(state){
            state.dialogLogout = true;
        },

        //角色侧边栏
        OPEN_USER_DRAWER(state) {
            state.userDrawer = !state.userDrawer
        },
        CANCEL_USER_DRAWER(state){
            state.userDrawer = null
        },

        //消息提醒侧边该你了
        OPEN_NEWS_DRAWER(state) {
            state.newsDrawer = !state.newsDrawer
        },
        CANCEL_NEWS_DRAWER(state){
            state.newsDrawer = null
        },



        //主菜单侧边栏
        OPERATE_NAV(state) {
            state.showNav = !state.showNav
        },
    }
}