import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import zhHans from "@/il8n/zh-Hans";
import en from "@/il8n/en";

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: {zhHans,en},  // 所有语言
		current: 'en', //当前语言
	},
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: "#ee44aa",
				secondary: "#424242",
				accent: "#82B1FF",
				error: "#FF5252",
				info: "#2196F3",
				success: "#4CAF50",
				warning: "#FFC107",
			},
		},
	},
	icons: {
		iconfont: "md",
	},
});
