<template>

    <v-app-bar elevation="0" app class="ma-4 rounded-lg" style="z-index: 20">
        <v-app-bar-nav-icon @click="operateNav"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>

        <v-btn v-if="isLogin" @click="operateNews" rounded icon>
            <v-badge overlap bordered color="green" :content="12">
                <v-icon>
                    mdi-bell
                </v-icon>
            </v-badge>
        </v-btn>

      <!-- 多语言选择菜单 -->
      <v-menu
          offset-y
          origin="center center"
          transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn text slot="activator" rounded class="py-6" v-on="on">
            <v-icon medium>mdi-translate</v-icon>
            <!-- 显示当前语言名称 -->
            <span class="ml-2"> {{ localeText }} </span>
            <v-icon small>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <!-- 显示所有语言包组成的下拉菜单 -->
        <v-list>
          <v-list-item-group v-model="$vuetify.lang.current">
            <v-list-item
                @click="handleChangeLocale(item)"
                v-for="item in availableLanguages"
                :key="item.value"
                :value="item.value"
            >
              <v-list-item-title v-text="item.text" />
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>


        <v-btn v-if="!isLogin"  elevation="0" rounded  class="primary py-6 lighten-5 blue--text"  @click="opLoginDialog1">
            <v-icon left size="22">
                mdi-login
            </v-icon>
          {{ $vuetify.lang.t("$vuetify.login") }}
        </v-btn>

        <div v-if="isLogin">
            <v-chip outlined class="transparent rounded-pill py-6" @click="operateUser">

                Hi,{{user.nickName}}
                <v-avatar class="ml-2" style="height: 55px;width: 55px">
                    <img
                            :src="user.avatar"
                    >
                </v-avatar>

            </v-chip>
        </div>
        <Login/>
    </v-app-bar>
</template>

<script>
    import {mapState} from "vuex";
    import Login from "./Login";

    export default {
        name: "Toolbar",
        components: {Login},
        data(){
          return{
          }
        },
        methods: {
            // 设置当前语言
            handleChangeLocale({ value }) {
              this.$vuetify.lang.current = value;
            },
            operateUser(){
              this.$store.commit('OPEN_USER_DRAWER')
            },
            operateNav() {
                this.$store.commit('OPERATE_NAV')
            },
            operateNews(){
              this.$store.commit('OPEN_NEWS_DRAWER')
            },
            opLoginDialog1() {
                this.$store.commit('OPEN_LOGIN');
            },

        },
        computed: {

          // 遍历并获取项目中的所有语言包
          availableLanguages() {
            const { locales } = this.$vuetify.lang; // 所有的语言，来自 src/plugins/vuetify.js 中的配置
            return Object.keys(locales).map((lang) => {
              return {
                text: locales[lang].label, // 语言包显示的名字
                value: lang, // 语言包的值
              };
            });
          },
          // 当前语言, 用于语言切换菜单中显示的当前语言名字
          localeText() {
            const find = this.availableLanguages.find(
                (item) => item.value === this.$vuetify.lang.current
            );
            return find.text;
          },


            ...mapState({
                isLogin: state => state.token.isLogin,
                user: state => state.token.getUserInfo,
            })
        },
    }
</script>

<style scoped>

</style>