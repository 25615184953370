<template>
    <div>
        <span id="copyTarget">{{targetCode}}</span>
        <v-btn id="copyBtn" :data-clipboard-text="targetCode" data-clipboard-action="copy" @click="copy"
               data-clipboard-target="#copyTarget">复制
        </v-btn>
    </div>
</template>
<script>
    import Clipboard from "clipboard";

    export default {
        data: () => ({
            targetCode: "阿萨德阿萨德阿萨德阿萨德",
        }),
        methods: {
            copy() {
                let clipboard = new Clipboard('#copyBtn');
                clipboard.on('success', e => {
                    this.$message.success("复制成功")
                    clipboard.destroy()
                })
                clipboard.on('error', e => {
                    this.$message.warning("该浏览器不支持自动复制")
                    clipboard.destroy()
                })
            },
        },
    }
</script>

<style scoped>


</style>