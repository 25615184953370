import Vue from 'vue'
import Vuex from 'vuex'

import token from "@/store/modules/token";
import nav from "@/store/modules/nav";
import drawer from "@/store/modules/drawer";
import snackbar from "@/store/modules/snackbar";

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		token,
		nav,
		drawer,
		snackbar
	},
})
