<template>
    <div>
        <!-- 登录 -->
        <v-dialog
                :retain-focus="false"
                v-model="dialogLogin.dialogLogin"
                persistent
                max-width="400px"
        >
            <v-card class="text-center">
                <v-avatar size="88" class="mt-3">
                    <v-img src="http://qn.1947.cloud/small/arctic-circle-logo.svg"/>
                </v-avatar>

                <h3 class="text--disabled font-weight-medium ">Sign in to your account</h3>

                <v-card-text class="mt-6">
                    <v-form ref="form" v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            prepend-inner-icon="mdi-cellphone"
                                            v-model="loginForm.phone"
                                            :rules="phoneRules"
                                            label="Phone"
                                            type="text"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" v-if="!codeLoginDialog">
                                    <v-text-field
                                            prepend-inner-icon="mdi-lock-outline"
                                            v-model="loginForm.password"
                                            :rules="passwordRules"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            label="Password"
                                            type="password"
                                            required
                                            @click:append="show1 = !show1"
                                            :type="show1 ? 'text' : 'password'"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="7" v-if="codeLoginDialog">
                                    <v-text-field
                                            prepend-inner-icon="mdi-code-string"
                                            label="code"
                                            v-model="loginForm.code"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" v-if="codeLoginDialog" style="padding: 17px">
                                    <v-btn
                                            color="secondary"
                                            @click="getLoginCode"
                                            large
                                            outlined
                                            block
                                            :disabled="loginCode"
                                    >
                                        {{ loginButText }}
                                    </v-btn>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="12">
                                    <v-btn
                                            dark
                                            color="blue darken-1"
                                            block
                                            @click="login"
                                    >
                                        <v-icon left>
                                            mdi-login
                                        </v-icon>

                                      {{ $vuetify.lang.t("$vuetify.login") }}
                                    </v-btn>

                                </v-col>

                                <v-col cols="12">
                                    <h4 style="color: steelblue">
                                      {{ $vuetify.lang.t("$vuetify.other_login") }}
                                    </h4>
                                    <v-divider></v-divider>
                                </v-col>

                                <v-col cols="12" v-if="!codeLoginDialog">
                                    <v-btn
                                            dark
                                            color="purple"
                                            block
                                            @click="opPasswordInput"
                                    >
                                        <v-icon dark left size="22">
                                            large
                                            mdi-cellphone-key
                                        </v-icon>
                                      {{ $vuetify.lang.t("$vuetify.sms_code") }}

                                    </v-btn>
                                </v-col>
                                <v-col cols="12" v-if="codeLoginDialog">
                                    <v-btn
                                            dark
                                            color="teal"
                                            block
                                            @click="opCodeLoginInput"
                                    >
                                        <v-icon left size="22">
                                            mdi-lock-check
                                        </v-icon>
                                      {{ $vuetify.lang.t("$vuetify.password") }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn

                                            @click="opWechat"
                                            block
                                            dark
                                            color="#388E3C"
                                    >
                                        <v-icon left size="22">
                                            mdi-wechat
                                        </v-icon>
                                        wechat
                                    </v-btn>
                                </v-col>

                                <v-col cols="6" class="text-left">
                                    <v-chip
                                        color="blue"
                                        outlined
                                        pill

                                            @click="opRegisterDialog"
                                    >
                                      <v-icon left>
                                        mdi-account-plus-outline
                                      </v-icon>
                                      {{ $vuetify.lang.t("$vuetify.register") }}
                                    </v-chip>
                                </v-col>

                                <v-spacer></v-spacer>
                                <v-col cols="4">
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="cancelLogin"
                                    >
                                      {{ $vuetify.lang.t("$vuetify.cancel") }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>

                </v-card-text>

            </v-card>
        </v-dialog>

        <!-- 扫码登录 -->
        <v-dialog


                v-model="opWechatDialog"
                persistent
                max-width="400px"
        >
            <v-card class="text-center">
                <v-avatar size="88" class="mt-3">
                    <v-img src="http://qn.1947.cloud/small/arctic-circle-logo.svg"/>
                </v-avatar>
                <div class="mt-3">
                    <Wxlogin appid="wx930936318d551153"
                             scope="snsapi_login"
                             theme="black"
                             :redirect_uri="encodeURIComponent('http://1947.cloud/#/Home')">
                    </Wxlogin>
                </div>

                <div class="pa-3">
                    <v-btn
                            dark
                            elevation="0"
                            color="blue"
                            block
                            @click="wechatCancel"
                    >
                        password ＆ sms code
                        <v-icon right color="yellow">
                            mdi-keyboard-return
                        </v-icon>
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

        <!-- 注册 -->
        <v-dialog
                v-model="registerLoading"
                transition="dialog-top-transition"
                max-width="440"
                persistent
        >

            <v-form ref="form">
                <v-card>

                    <v-container>
                        <v-row>
                            <v-col class="text-right px-0" cols="12" md="7">
                                <v-avatar size="88" class="mt-3">
                                    <v-img src="http://qn.1947.cloud/small/logo.f6be0e6.svg"/>
                                </v-avatar>
                            </v-col>

                            <v-col class="text-right" cols="12" md="5">
                                <v-btn
                                        large
                                        color="blue darken-1"
                                        icon
                                        @click="registerClose"
                                >
                                    <v-icon>
                                        mdi-close
                                    </v-icon>

                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-card-text>
                        <h3 class="text--disabled font-weight-medium text-center ">Sign up to use our service</h3>

                        <v-container>
                            <v-row>
                                <v-col cols="12" md="5">
                                    <v-text-field
                                            prepend-inner-icon="mdi-rename"
                                            label="Nick Name"
                                            v-model="registerForm.name"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="7">
                                    <v-text-field
                                            prepend-inner-icon="mdi-at"
                                            label="email*"
                                            hint="Please enter a valid email address"
                                            v-model="registerForm.email"
                                            :rules="emailRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                            prepend-inner-icon="mdi-lock-outline"
                                            label="Password*"
                                            type="password"
                                            v-model="registerForm.password"
                                            :rules="passwordRules"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                            prepend-inner-icon="mdi-lock-alert-outline"
                                            label="Confirm password*"
                                            type="password"
                                            v-model="registerForm.confirmPassword"
                                            :rules="passwordTwoRules"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                            prepend-inner-icon="mdi-cellphone"
                                            label="Phone"
                                            v-model="registerForm.phone"
                                            :rules="phoneRules"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                            prepend-inner-icon="mdi-code-string"
                                            label="code"
                                            v-model="registerForm.code"
                                            required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn
                                            dark
                                            color="secondary"
                                            @click="getRegisterCode"
                                            large
                                            outlined
                                            block
                                            :disabled="registerCode"
                                    >
                                        {{ registerButText }}
                                    </v-btn>
                                </v-col>

                                <v-col cols="12">
                                    <v-btn
                                            dark
                                            elevation="0"

                                            block
                                            color="blue darken-1"
                                            left
                                            @click="register"
                                    >
                                        <v-icon left size="22">
                                            mdi-account-plus
                                        </v-icon>
                                        register
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
    import Wxlogin from 'vue-wxlogin'
    import {login, register, verifyPhone, sendSms} from "../api/system/login";
    import {mapState} from "vuex";

    export default {
        name: "Login",
        components: {
            Wxlogin
        },
        data() {
            return {
                show1: false,
                valid: false,
                loading: false,
                phoneInput: false,
                opWechatDialog: false, //微信登录对话框
                registerLoading: false, //注册对话框
                codeLoginDialog: false,//密码获验证码输入框切换
                registerTime: 120,
                loginTime: 120,
                registerButText: 'GET CODE',
                registerCode: false,//注册验证码按钮 是否禁用
                loginCode: false,//登录验证码按钮 是否禁用
                loginButText: 'GET CODE',
                loginForm: {
                    phone: '',
                    password: '',
                    loginType: 0,
                    code: ''
                },
                registerForm: {
                    name: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    phone: '',
                    code: ''
                },
                phoneRules: [
                    v => !!v || 'phone is required',
                    v => /^1[3|4|5|7|8][0-9]{9}$/.test(v) || 'phone incorrect format'
                ],
                passwordRules: [
                    v => !!v || 'password is required',
                    v => /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(v) || 'The password must contain 6 to 18 characters and one letter'
                ],
                passwordTwoRules: [
                    v => !!v || 'password is required',
                    v => v === this.registerForm.password || 'Confirm Password error',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                codeRule: [
                    v => !!v || 'code is required',
                    v => /^[0-9]\d{5}$/.test(v) || 'code incorrect format',
                ],
            }
        },

        methods: {
            wechatCancel() {
                this.$store.commit('OPEN_LOGIN');
                this.opWechatDialog = !this.opWechatDialog
            },
            opWechat() {
                this.$store.commit('CANCEL_LOGIN');
                this.opWechatDialog = !this.opWechatDialog
            },
            registerClose() {
                this.registerLoading = !this.registerLoading
                this.registerForm = {}
            },
            cancelLogin() {
                this.$store.commit('CANCEL_LOGIN');
                this.loading = false
            },
            opRegisterDialog() {
                this.registerLoading = !this.registerLoading
                this.$store.commit('CANCEL_LOGIN');
            },

            //打开验证码登录
            opCodeLoginInput() {
                this.codeLoginDialog = !this.codeLoginDialog
                this.loginForm.loginType = 0
            },
            //打开验证码登录
            opPasswordInput() {
                this.codeLoginDialog = !this.codeLoginDialog
                this.loginForm.loginType = 1
            },

            login() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    this.enter(this.loginForm)
                }
            },
            //登录
            enter(params) {
                login(params).then(res => {
                    this.$store.commit('LOGIN_IN', res);
                    this.$store.commit('SET_MENU', res)
                    this.$store.commit('CANCEL_LOGIN');
                    this.loading = false;
                    this.opWechatDialog = false;
                    location.reload()
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
            },

            //注册
            register() {
                if (this.$refs.form.validate()) {
                    register(this.registerForm).then(() => {
                        this.registerLoading = false
                        this.$store.commit('CANCEL_LOGIN');
                        this.loginForm.phone = this.registerForm.phone
                    })
                }
            },
            //获取注册验证码
            getRegisterCode() {
                if (this.registerCode) return
                if (this.$refs.form.validate()) {
                    const phone = {
                        phone: this.registerForm.phone
                    }
                    verifyPhone(phone).then(() => {
                        //获取上次发送时间
                        let endTime = Math.ceil((localStorage.getItem('REGISTER_CODE_TIME') - new Date().getTime()) / 1000)
                        //上次发送时间过了一分钟后,可以发送新验证码
                        if (endTime < 1) {
                            const params = {
                                phone: this.registerForm.phone,
                                smsType: 1,
                            }
                            //发送验证码
                            sendSms(params)
                            const startTime = JSON.stringify(new Date().getTime() + 120000);
                            localStorage.setItem('REGISTER_CODE_TIME', startTime)

                        } else {
                            this.registerTime = endTime
                        }
                        this.countDown('REGISTER')
                    })
                }
            },
            //获取登录验证码
            getLoginCode() {
                if (this.loginCode) return
                if (this.$refs.form.validate()) {
                    //获取上次发送时间
                    let endTime = Math.ceil((localStorage.getItem('LOGIN_CODE_TIME') - new Date().getTime()) / 1000)
                    //上次发送时间过了一分钟后,可以发送新验证码
                    if (endTime < 1) {
                        const params = {
                            phone: this.loginForm.phone,
                            smsType: 0,
                        }
                        //发送验证码
                        sendSms(params)
                        const startTime = JSON.stringify(new Date().getTime() + 120000);
                        localStorage.setItem('LOGIN_CODE_TIME', startTime)

                    } else {
                        this.loginTime = endTime
                    }
                    this.countDown('LOGIN')
                }
            },
            //获取登录验证码倒计时
            countDown(type) {
                if (type === 'LOGIN') {
                    let clock = window.setInterval(() => {
                        this.loginCode = true
                        this.loginTime--
                        this.loginButText = this.loginTime + 's'
                        if (this.loginTime < 0) {
                            window.clearInterval(clock)
                            this.loginButText = 'GET CODE'
                            this.loginTime = 120
                            this.loginCode = false
                        }
                    }, 1000)
                }
                if (type === 'REGISTER') {
                    let clock = window.setInterval(() => {
                        this.registerCode = true
                        this.registerTime--
                        this.registerButText = this.registerTime + 's'
                        if (this.registerTime < 0) {
                            window.clearInterval(clock)
                            this.registerButText = 'GET CODE'
                            this.registerTime = 120
                            this.registerCode = false
                        }
                    }, 1000)
                }
            }
        },
        computed: {

            ...mapState({
              dialogLogin: state => state.drawer,
                isLogin: state => state.token.isLogin,
                avatar: state => state.token.getUserInfo.avatar,
                email: state => state.token.getUserInfo.email,
            })
        },
        created() {
            //微信扫码轮询
            setInterval(() => {
                let wxCode = this.$route.query.code;
                if (wxCode) {
                    const params = {
                        code: wxCode,
                        loginType: 2
                    }
                    this.enter(params)
                    //清除浏览器微信code
                    this.$router.push(this.$route.path);
                }
            })
        }
    }
</script>

<style scoped lang="scss">

    .btn-custom-lg {
        padding: 15px 125px !important;
    }

    .linking {
        i {
            margin-left: 10px;
            transition: 0.2s ease-in;
            font-size: 16px;
        }
        &:hover {
            i {
                margin-left: 15px;
            }
        }
    }

    .btn-arrow {
        position: relative;

    span {
        display: inline-block;
        position: relative;
        transition: all 300ms ease-out;
        will-change: transform;
    }

    &:hover span,
    &:focus span {
         transform: translate3d(-0.5rem, 0, 0);
     }

    i {
        position: absolute;
        width: 1.1em;
        right: 0px;
        right: 0rem;
        opacity: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all 300ms ease-out;
        will-change: right, opacity;
    }

    &:hover i,
    &:focus i {
         opacity: 1;
         right: -2rem;
     }
    }

</style>