<template>
  <v-navigation-drawer
      @input="closeUserDrawer"
      fixed
      temporary
      right
      v-model="userDrawer.userDrawer"
      style="width: 420px;z-index: 21"
  >

    <v-toolbar elevation="0" color="grey lighten-4">
      <h3>My Account</h3>
    </v-toolbar>

    <v-card flat light class="pt-6 text-center">

      <v-avatar size="88px" class="mb-3">
        <v-img :src="user.avatar"/>
      </v-avatar>

      <h3 class="text-h5 mb-3">{{ user.nickName }}</h3>
      <div class="mb-2 blue--text"> {{ user.email }}</div>
      <v-divider light class="mb-2"></v-divider>


      <div class="row text-left pt-2">
        <strong class="text-right mr-4 mb-2 col">role:</strong>
        <div class="col" style="margin-top: -3px">
          <v-chip v-if="user.roleId === 0" outlined  color="red">
            <v-icon left small>
              mdi-account
            </v-icon>
            system
          </v-chip>

          <v-chip v-if="user.roleId === 1" outlined  color="secondary">
            <v-icon left small>
              mdi-account
            </v-icon>
            ordinary-system
          </v-chip>

          <v-chip v-if="user.roleId === 2" outlined  color="secondary">
            <v-icon left small>
              mdi-account
            </v-icon>
            ordinary
          </v-chip>
        </div>
      </div>

      <div class="row text-left">
        <strong class="text-right mr-4 mb-2 col">phone:</strong>
        <div class="col" style="margin-top: -3px">
          <v-chip outlined  color="green">
            <v-icon left small>
              mdi-cellphone
            </v-icon>
            {{ user.phone }}
          </v-chip>
        </div>
      </div>


      <div class="row text-left">
        <strong class="text-right mr-4 mb-2 col">gender:</strong>
        <div class="col" style="margin-top: -3px">
          <v-chip v-if="user.sex === 0" outlined  color="blue" >
            <v-icon  left small>
              mdi-gender-male
            </v-icon>
            male
          </v-chip>

          <v-chip v-if="user.sex === 1" outlined  color="blue" >
            <v-icon  left small>
              mdi-gender-female
            </v-icon>
            female
          </v-chip>

          <v-chip v-if="user.sex > 1" outlined  color="blue" >
            <v-icon  left small>
              mdi-gender-transgender
            </v-icon>
            unknown
          </v-chip>
        </div>
      </div>

      <div class="row text-left">
        <strong class="text-right mr-4 mb-2 col">registrationDate:</strong>
        <div class="col" style="margin-top: -3px">

            <v-chip outlined >
              <v-icon left small>
                mdi-calendar-range
              </v-icon>
              {{ user.createdTime }}
            </v-chip>

        </div>
      </div>

      <div class="row text-left">
        <strong class="text-right mr-4 mb-2 col ">lastLoginDate:</strong>
        <div class="col" style="margin-top: -3px">

          <v-chip outlined >
            <v-icon left small>
              mdi-clock-time-eight
            </v-icon>
            {{ user.updatedTime }}
          </v-chip>
        </div>
      </div>

    </v-card>


    <template v-slot:append>
      <div class="pa-3">
        <v-btn elevation="0" block class="primary lighten-5 blue--text" @click="openLogout"  >
          <v-icon>
            mdi-logout
          </v-icon>
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "UserDrawer",

  computed: {
    ...mapState({
      userDrawer: state => state.drawer,
      user: state => state.token.getUserInfo
    })
  },

  methods: {
    //打开退出系統确认框
    openLogout() {
      this.$store.commit('OPEN')
    },

    closeUserDrawer() {
      this.$store.commit('CANCEL_USER_DRAWER')
    }
  }
}
</script>

<style scoped>

</style>