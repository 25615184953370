<template>
  <v-card
          class="mx-auto" style="overflow: auto; height: 87vh"
  >
    <v-container >
      <v-row dense>
        <v-col
                v-for="card in cards"
                :key="card.title"
                :cols="card.flex"
        >
          <v-card>
            <v-img
                    :src="card.src"
                    class="white--text mt-8 align-end"
                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    height="200px"
            >
              <v-card-title class="white--text mt-8">
                <v-avatar size="56">
                  <img
                          alt="user"
                          src="https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg"
                  >
                </v-avatar>
              </v-card-title>
              <v-card-title v-text="card.title"></v-card-title>

            </v-img>

            <v-card-actions>
              <v-timeline
                      align-top
                      dense
              >
                <v-timeline-item
                        v-for="message in messages"
                        :key="message.time"
                        :color="message.color"
                        small
                >
                  <div>
                    <div class="font-weight-normal">
                      <strong>{{ message.from }}</strong> @{{ message.time }}
                    </div>
                    <div>{{ message.message }}</div>
                  </div>
                </v-timeline-item>
              </v-timeline>




            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      cards: [
        { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 4 },
        { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 4 },
        { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 3 },
        { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 3 },
        { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 3 },
        { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 3 },

      ],
      messages: [
        {
          from: 'You',
          time: '10:42am',
          color: 'deep-purple lighten-1',
        },
        {
          from: 'John Doe',
          time: '10:37am',
          color: 'green',
        },
        {
          from: 'You',
          time: '9:47am',
          color: 'deep-purple lighten-1',
        },
      ],
    }),
  }
</script>
<style scoped>

</style>