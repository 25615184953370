import axios from "../../utils/http";
import store from "../../store";

/**
 * 登录
 * @param user
 * @returns {Promise<unknown>}
 */
export async function login(user) {
    return new Promise((resolve, reject) => {
        axios.post("system/login", user).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
}

/**
 *  发送注册验证码验证手机是否已使用
 * @param params
 * @returns {Promise<any>}
 */
export async function verifyPhone(params) {
    const callBackData = await axios.post("system/user/verifyPhone", params);
    if (!callBackData) return;
    return callBackData;
}

/**
 * 注册
 * @param params
 * @returns {Promise<any>}
 */
export async function register(params) {
    const callBackData = await axios.post("system/user/register", params);
    store.dispatch('openSnackbar',{msg:'用户注册成功'})
    if (!callBackData) return;
    return callBackData;
}

/**
 * 短信发送
 * @param params
 * @returns {Promise<any>}
 */
export async function sendSms(params) {
    const callBackData = await axios.post("portal/sms/sendSms", params);
    store.dispatch('openSnackbar',{msg:'短信发送成功'})
    if (!callBackData) return;
    return callBackData;
}

