import axios from "axios";
import baseUrl from "@/utils/baseURL";
import store from "../store";

const http = {}

var instance = axios.create({
    timeout: 5000
    // baseURL
})

//添加拦截器
instance.interceptors.request.use((config => {
    //请求添加token
    config.headers.Authorization = 'Bear ' + localStorage.getItem('token');
    return config;
}), error => {
    return Promise.reject(error)
})


//响应拦截器异常处理
instance.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '请求出错'
                    break
                case 401:
                    store.dispatch('openSnackbar', {msg: '授权过期,请重新登录', type: 'warn'})
                    localStorage.clear()
                    store.commit('OPEN_LOGIN')
                    break
                case 503:
                    store.dispatch('openSnackbar', {msg: '服务器不存在的资源', type: 'warn'})
                    break
            }
        } else {
            error.message = '链接服务器失败'
        }
    }
)

http.post = function (url, data, options) {
    return new Promise(((resolve, reject) => {
        instance.post(baseUrl + url, data, options)
            .then(response => {
                if (response.code === 200) {
                    resolve(response.data)
                } else {
                    if (response.message === 'FailedOperation.InsufficientBalanceInSmsPackage') {
                        store.dispatch('openSnackbar', {msg: '短信套餐包余量不足', type: 'err'})
                        return
                    }
                    store.dispatch('openSnackbar', {msg: response.message, type: 'err'})
                    reject(response.message)
                }
            })
            .catch(e => {
                store.dispatch('openSnackbar', {msg: e, type: 'err'})
            })
    }))
}

export default http

