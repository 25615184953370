<template>
  <v-snackbar top v-model="visible" :color="color">

    <h3>
      <v-icon left >
        {{ this.$store.state.snackbar.icon }}
      </v-icon>
      {{ this.$store.state.snackbar.msg }}
    </h3>
    <!-- 关闭按钮 -->
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" v-if="showClose" icon @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    visible() {
      return this.$store.state.snackbar.visible;
    },
    icon() {
      return this.$store.state.snackbar.icon;
    },
    showClose() {
      return this.$store.state.snackbar.showClose;
    },
    color() {
      return this.$store.state.snackbar.color;
    },
  },
  methods: {
    close() {
      this.$store.commit("CLOSE_SNACKBAR");
    },
  },
};
</script>