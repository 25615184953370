import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Layout",
		component: () => import("../components/Layout"),
		redirect: 'Home', //重定向 容器页面
		children: [
			{
				path: "Home",
				name: "Home",
				component: () => import("../components/Home"),
			}
			,
			{
				path: "test",
				name: "test",
				component: () => import("../views/sys/Test"),
			},

			{
				path: "User",
				name: "user",
				component: () => import("../views/user/User"),
			},

			{
				path: "Note",
				name: "note",
				component: () => import("../views/note/NewNoteTest"),
			},
		]
	},
];

const router = new VueRouter({
	routes,
});

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
	return routerPush.call(this,location).catch(() => {})
}

export default router;
