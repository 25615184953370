export default {
    state: {
        menuDate: JSON.parse(localStorage.getItem("menu")),
    },

    mutations: {
        SET_MENU(state, data) {
            localStorage.setItem("menu", JSON.stringify(data.sysMenus))
        },
        SET_ROUTE(state, router) {
            const routerList = []
            const menu = state.menuDate
            //保存菜单
            if (menu !== null) {
                menu.forEach(item => {
                    if (item.children.length) {
                        item.children = item.children.map(item => {
                            item.component = () => import(`@/views/${item.url}`)
                            return item
                        })
                        routerList.push(...item.children)
                    } else {
                        item.component = () => import(`@/views/${item.url}`)
                        routerList.push(item)
                    }
                })
                routerList.forEach(item => {
                    //Layout是父路由名称, item添加到Layout的children下
                    router.addRoute('Layout', item)
                })
            }
        }

    },

}