import en from 'vuetify/es5/locale/en';

export default {
    label: 'English',
    //page login
    login: 'Log in',
    other_login: 'other login',
    sms_code: ' sms code',
    password: 'password',
    register: 'create account.',
    cancel: 'cancel',

    username: 'Username',

    login_account: 'Login accoount',
    // menu
    menu: {
        Home: 'Home',
        Note: 'Note',
    },
    //主頁
    home: {
        feature: 'Feature',
        introduce_1: 'A solution to multi-terminal note reading and editing applications',
        introduce_2: 'This application is provided free of charge by Nick and is permanently free. Welcome to promote it. Open source code,',
        learn: 'learn more.'
    },
    //
    ...en,
}

