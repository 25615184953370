<template>

    <div>

        <v-row>
            <v-col cols="12" lg="10" class="pa-16">
                <v-img
                        src="http://qn.1947.cloud/small/feature-img.jpg"
                        class="rounded"
                />
            </v-col>

            <v-col cols="12" md="7" lg="5" class="feature3-card pa-16">
                <v-card class="card-shadow">
                    <v-card-text>
                        <div class="pa-10 text-center">
                            <v-chip small color="info">
                                {{ $vuetify.lang.t("$vuetify.home.feature") }}
                            </v-chip>
                            <h3 class="feature3-title font-weight-medium">
                                {{ $vuetify.lang.t("$vuetify.home.introduce_1") }}
                            </h3>
                            <p>
                                {{ $vuetify.lang.t("$vuetify.home.introduce_2") }}
                                <a @click="$router.push('Note')" class="blue--text">
                                    {{ $vuetify.lang.t("$vuetify.home.learn") }}
                                </a>
                            </p>
                            <v-btn
                                    color="error"
                                    class="btn-custom-lg linking mt-10 btn-arrow"
                                    large
                                    to="Note"
                                    @click="$router.push('Note')"
                                    elevation="0"
                            >
                                <span>Mention experience</span>
                                <i class="mdi mdi-arrow-right"></i>
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>


        </v-row>

        <v-row>
            <v-col cols="12" >
                <v-alert class="text-center"  >
                    <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2022013573号-2 </a>
                </v-alert>
            </v-col>

        </v-row>
    </div>

</template>


<script>
    export default {
        name: "Home",
    }
</script>

<style scoped lang="scss">

    .feature3-component {
        position: relative;
    }

    .feature3-card {
        padding: 20px;
        position: absolute;
        top: 17%;
        right: 0;

        .feature3-title {
            font-size: 24px;
            line-height: 30px;
            margin: 20px 0 15px;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .feature3-card {
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 50%;
        }
    }

    @media (max-width: 767px) {
        .feature3-card {
            position: relative;
        }
    }

    @media (max-width: 767px) {
        .mini-spacer {
            padding: 45px 0;
        }
    }

    .position-relative {
        position: relative !important;
    }

    .card-shadow {
        box-shadow: 0 0 30px rgb(115 128 157 / 10%) !important;
    }

    .btn-custom-lg {
        padding: 15px 45px !important;
    }

    .linking {
        i {
            margin-left: 10px;
            transition: 0.2s ease-in;
            font-size: 16px;
        }

        &:hover {
            i {
                margin-left: 15px;
            }
        }
    }

    .btn-arrow {
        position: relative;

        span {
            display: inline-block;
            position: relative;
            transition: all 300ms ease-out;
            will-change: transform;
        }

        &:hover span,
        &:focus span {
            transform: translate3d(-0.5rem, 0, 0);
        }

        i {
            position: absolute;
            width: 1.1em;
            right: 0px;
            right: 0rem;
            opacity: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: all 300ms ease-out;
            will-change: right, opacity;
        }

        &:hover i,
        &:focus i {
            opacity: 1;
            right: -2rem;
        }
    }


</style>