<template>
    <v-app id="inspire">

        <!-- 菜单 -->
        <Nav/>

        <!-- 全局消息条 -->
        <Snackbars/>

        <!-- 通知菜单 -->
        <NewsDrawer v-if="isLogin"/>

        <!-- 角色菜单 -->
        <UserDrawer v-if="isLogin"/>
        <!-- 标头 -->
        <Toolbar/>

        <v-main class="grey lighten-2">
            <v-container class="py-8  px-4 ">
                <!-- 路由出口 -->
                <router-view/>
            </v-container>
        </v-main>

    </v-app>
</template>

<script>
    import Nav from "./Nav";
    import Toolbar from "./Toolbar";
    import UserDrawer from "./drawer/UserDrawer";
    import NewsDrawer from "./drawer/NewsDrawer";
    import Snackbars from "@/components/snackbars/Snackbars";
    import {mapState} from "vuex";
    export default {
        components: {Snackbars, NewsDrawer, Nav, Toolbar, UserDrawer},
        computed: {
          ...mapState({
            isLogin: state => state.token.isLogin,
          })
        },
    };
</script>

<style scoped>

</style>


