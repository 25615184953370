<template>
    <v-dialog v-model="dialogLogout.dialogLogout" max-width="500px" dark>
        <v-card style="background-color: #363636">
            <v-card-title class="text-h5">Are you sure you want to quit SmallNote?</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue" text @click="cancel">Cancel</v-btn>
                <v-btn color="teal"  @click="logout">OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapState} from "vuex";
    export default {
        name: "DialogLogout",
        data(){
          return{
          }
        },

        computed: {
            ...mapState({
                dialogLogout: state => state.drawer,
            })
        },
        methods: {
            //登出
            logout() {
                this.$store.commit('LOGIN_OUT')
                this.$store.commit('CANCEL')
                this.$router.push('/')
                location.reload()
            },
            //取消退出登录
            cancel() {
                this.$store.commit('CANCEL')
            },

        }
    }
</script>

<style scoped>

</style>