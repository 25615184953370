<template>
  <v-navigation-drawer
      @input="closeNewsDrawer"
      fixed
      temporary
      right
      v-model="newsDrawer.newsDrawer"
      style="width: 420px;z-index: 21"
  >

    <v-toolbar elevation="0" color="grey lighten-4">
      <h3>Message Notification</h3>
    </v-toolbar>

  </v-navigation-drawer>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "NewsDrawer",

  computed: {
    ...mapState({
      newsDrawer: state => state.drawer,
    })
  },

  methods: {
    closeNewsDrawer() {
      this.$store.commit('CANCEL_NEWS_DRAWER')
    }
  }
}
</script>

<style scoped>

</style>