<template>
    <v-app>
        <router-view/>
    </v-app>
</template>

<script>
    export default {
        name: "App",
        data() {
            return {};
        },
    };
</script>

<style>

    ::-webkit-scrollbar {
        /* 滚动条整体样式 */
        width: 6px;
        /* 宽高分别对应横竖滚动条的尺寸 */
        background: rgba(235, 238, 245, 0);
        border-radius: 10px;
        -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar-thumb {
        /* 滚动条里的小方块 */
        border-radius: 3px;
        background: rgba(49, 171, 171, 0.94);
        height: 120px;
    }

    ::-webkit-scrollbar-track {
        /* 滚动条里面的轨道 */
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
        border-radius: 10px;
        background: rgba(0, 0, 0, 0);
    }

</style>
