<template>

    <v-sheet class="d-flex pa-3"
             elevation="3"
             color="teal"
             shaped>
        <v-menu
                bottom
                min-width="230px"
                rounded
                offset-y>

            <template v-slot:activator="{ on }">
                <v-btn icon x-large v-on="on">
                    <v-avatar size="63">
                        <img :src="avatar"/>
                    </v-avatar>
                </v-btn>
            </template>

            <v-card style="background-color: teal">
                <v-list-item-content class="justify-center">
                    <div class="mx-auto text-center">
                        <v-avatar color="brown" size="88">
                            <img :src="avatar"/>
                        </v-avatar>
                        <h3 style="color: #C8E6C9">{{ name }}</h3>
                        <p style="color: skyblue" class="text-caption mt-1">{{ email }}</p>
                        <v-divider style="background-color: #C8E6C9" class="my-3"></v-divider>
                        <v-btn
                                depressed
                                rounded
                                text
                                color="#C8E6C9"
                                @click="$router.push('/User')"
                        >
                            Edit Account
                        </v-btn>
                        <v-divider style="background-color: #C8E6C9" class="my-3"></v-divider>
                        <v-btn
                                depressed
                                rounded
                                text
                                color="#C8E6C9"
                                @click="openLogout"
                        >
                            Disconnect
                        </v-btn>
                    </div>
                </v-list-item-content>
            </v-card>
        </v-menu>

        <h3 class="pa-4" style="color: #d1e7e7;">{{ name }}</h3>
    </v-sheet>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "Avatar",
        data: () => ({
            user: {
                initials: 'JD',
                fullName: 'John Doe',
                email: 'john.doe@doe.com',
            },
        }),
        methods: {
            //打开退出登录确认框
            openLogout() {
                this.$store.commit('OPEN')
            }
        },
        computed: {
            ...mapState({
                avatar: state => state.token.getUserInfo.avatar,
                email: state => state.token.getUserInfo.email,
                name: state => state.token.getUserInfo.nickName,
            })
        },
    }
</script>

<style scoped>

</style>